
import { UserMessage } from '@/models/user-message';
import { ApiService, LoaderService, UserService } from '@/services';
import { defineComponent, onMounted, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';

export default defineComponent({
	components: {
		InputText,
		Textarea,
		Button
	},
	setup() {
		const message = reactive<UserMessage>({
			contact: '',
			source: '',
			content: ''
		});
		const toast = useToast();

		onMounted(async () => {
			await resetMessage();
		});

		const resetMessage = async () => {
			const profile = await UserService.get();
			message.contact = profile.value.displayName;
			message.source = profile.value.email;
			message.content = '';
		};

		const submit = async () => {
			const loader = LoaderService.invoke();

			try {
				await ApiService.post('/feedback', message);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: "Your message was sent successfully. We'll be in touch.",
					life: 3000
				});

				await resetMessage();
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to send email. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(loader);
			}
		};

		return { submit, message };
	}
});
